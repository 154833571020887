.KsN3pMPu2H {
  height: 450px;
  position: relative;
}
.KsN3pMPu2H .table {
  background: rgba(51, 51, 51, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.KsN3pMPu2H .table h1 {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: bold;
  font-size: 2.5em;
}

@media only screen and (max-width: 640px) {
  .KsN3pMPu2H {
    height: 400px;
  }
  .KsN3pMPu2H .table h1 {
    font-size: 1.75em;
  }
}

.UcQCTe1y1ic2l99 .columns
{
  height:500px;
  position:relative;
  padding:0;
  border-top: 10px solid white;
  border-bottom: 10px solid white;
  border-left: 10px solid white;
}

.UcQCTe1y1ic2l99 .columns a
{
  color:#fff;
}

.UcQCTe1y1ic2l99 .table
{
  text-align:center;
}

.rnLcue1y1ihr1r4
{
  position:absolute;
  top:0;
  left:0;
  width:175px;
  padding-left:40px;
  background:#ff6363;
  height:40px;
  margin-top:40px;
  line-height:40px;
}

.tHdWoe1y1iv1n2p6
{
  line-height:40px;
  color:#fff;
  text-transform:uppercase;
  letter-spacing:4px;
}

.TqUaEe1y1i111q1t7
{
  background:rgba(0,0,0,0.8);
  display:none;
  color:#fff;
}
.TqUaEe1y1i111q1t7.table
{
  display:none;
}

.TqUaEe1y1i111q1t7 h2
{
  text-transform:uppercase;
  letter-spacing:5px;
  font-size:1.75em;
  max-width:200px;
  text-align:left;
  font-weight:700;
  margin:0 auto 20px;
}

.HSSsTe1y1i142m1z1
{
  text-transform:uppercase;
  letter-spacing:4px;
  font-size:1em;
  text-align:left;
  max-width:200px;
  margin-left:auto;
  margin-right:auto;
  display:block;
  margin-bottom:10px;
}

.WEYdte1y1i1623l5
{
  display:block;
  max-width:200px;
  width:100%;
  color:#fff;
  height:40px;
  line-height:40px;
  border:1px solid #fff;
  text-transform:uppercase;
  letter-spacing:4px;
  font-weight:700;
  transition:all .2s ease-in-out;
  margin:0 auto;
}

.WEYdte1y1i1623l5:hover
{
  background:#fff;
  color:#000;
}

@media only screen and (max-width: 1440px) {
  .UcQCTe1y1ic2l99 .columns
  {
    height:300px;
  }
}

@media only screen and (max-width: 800px) {
  .UcQCTe1y1ic2l99 .columns
  {
    height:225px;
  }

  .TqUaEe1y1i111q1t7 h2
  {
    font-size:1.15em;
  }

  .rnLcue1y1ihr1r4
  {
    width:145px;
    padding-left:20px;
  }
}

@media only screen and (max-width: 500px) {
  .UcQCTe1y1ic2l99 .columns
  {
    height:250px;
  }
}
