header {
  height: 90px;
  line-height: 90px;
  -webkit-box-shadow: 0px 3px 5px rgba(100, 100, 100, 0.49);
  -moz-box-shadow:    0px 3px 5px rgba(100, 100, 100, 0.49);
  box-shadow:         0px 3px 5px rgba(100, 100, 100, 0.49);
  overflow: hidden;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
  margin-top: 0px !important;
  z-index: 8;

  .row {
    position: relative;
  }

  .rated-best {
    position: absolute;
    width: 300px;
    height: 90px;
    left: 8%;
    top: 0;
  }

  span {
    display: block;
    width: 100%;
    line-height: 1.5;
    text-align: center;
    margin-top: 15px;
    font-size: 1.1em;

    strong {
      font-weight: normal;
      font-size: 1.5em;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
  }

  p {
    height: 90px;
    line-height: 90px;
    box-sizing: border-box;
  }

  li.social {
    margin-top: 25px;
    float: right;
    &.facebook {
      a {
        background: #3b5998;
      }
    }

    &.instagram {
      a {
        background: #405de6;
      }
    }

    a {
      display: block;
      width: 35px;
      height: 35px;
      color: #fff !important;
      border-radius: 8px;
      line-height: 35px;
      text-align: center;
      font-size: 1.1em;

      img {
        display: block;
        width: 35px;
        height: 35px;
        margin-left: 0px;
        margin-top: 0px;
      }

      i {
        color: #fff;
        line-height: 1;
      }
    }
  }
}
header.stuck
{
  position: fixed;
}
header a.contains_logo {
  float: left;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 1.1em;
  color: #444;
}
header img {
  display: block;
  height: 80px;
  width: auto;
  margin-top: 5px;
  margin-left: 40px;
}
header .bars {
  display: none;
}
header nav {
  text-align: right;
  box-sizing: border-box;
  padding-right: 40px;
}
header nav ul {
  line-height: 90px;
}
header nav ul li {
  display: inline-block;
  padding-left: 15px;
}
header nav ul li a {
  color: #444;
  font-size: 1.15em;

  &.r-now-btn-hdr {
    height: 40px;
    line-height: 40px;
    border-radius: 9999px;
    display: block;
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
    background: #ff6363;
    color: #fff;
    font-weight: bolder;
  }
}

.mobile-nav {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(28, 28, 28, 0.8);
  z-index: 99999;
  position: fixed;
  text-align: center;
  display: none;

  ol {
    padding-top: 20px;
  }

  ol li {
    display: inline-block;
    line-height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: top;

    &.facebook {
      a {
        background: #3b5998;
      }
    }

    &.instagram {
      a {
        background: #405de6;
      }
    }

    a,
    img {
      color: #fff;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      padding: 0px;
      display: block;
    }

    a {
      border-radius: 8px;
    }
  }
}
.mobile-nav li {
  display: block;
  text-align: center;
}
.mobile-nav li a {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
}
.mobile-nav li a:hover {
  background: #000;
}
.mobile-nav .exit-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  color: #fff;
  margin-top: 20px;
  margin-right: 20px;
}
.mobile-nav .exit-button i {
  font-size: 2em;
  cursor: pointer;
}
.mobile-nav .exit-button i:hover {
  color: #0074D9;
}

@media only screen and (max-width: 1200px) {
  header nav {
    display: none;
  }

  header .rated-best {
    left: 10%;
  }

  header .columns {
    padding-left: 20px;
    padding-right: 20px;
  }
  .span-container {
    display: none;
  }
  header .bars {
    text-align: right;
    padding-top: 3px;
    display: block;
  }
  header .bars i {
    font-size: 2em;
    cursor: pointer;
    color: #666;
  }
  header .bars i:hover {
    color: #0074D9;
  }
}

@media only screen and (max-width: 1024px) {
  header .rated-best {
    margin-left: 1%;
  }

  header img {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 990px) {
  header .rated-best {
    left: 7%;
  }
}

@media only screen and (max-width: 820px) {
  header .rated-best {
    margin-left: 2%;
  }
}

@media only screen and (max-width: 710px) {
  header .rated-best {
    margin-left: 4%;
  }
}

@media only screen and (max-width: 640px) {
  header .rated-best {
    display: none;
  }

  header img {
    margin-left: 0px;
  }
}
