.P57d7ef9772da657d7ef9772dc5 {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  margin-top: 90px;

  &-bg {
    padding-top: 0;
    padding-bottom: 0;

    h1,
    h2 {
      color: #fff !important;
    }

    .table {
      background: rgba(#000, 0.8);
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
}
.P57d7ef9772da657d7ef9772dc5 h2 {
  font-weight: normal;
  margin-bottom: 10px;
}
.P57d7ef9772da657d7ef9772dc5 a {
  color: #fff;
}

.V57d7f4f5694ee57d7f4f5694f9 {
  display: block;
  height: 280px;
  width: 100%;
  background: #333;
  padding: 0px;
}
.V57d7f4f5694ee57d7f4f5694f9 .table {
  background: transparent;
  transition: all 0.2s ease-in-out;
}
.V57d7f4f5694ee57d7f4f5694f9:hover .table {
  background: rgba(255, 255, 255, 0.3);
}

@media screen and (min-width: 40em) {
  .P57d7ef9772da657d7ef9772dc5 .columns {
    padding-bottom: .9375rem;
    padding-top: .9375rem;
  }
}
@media screen and (max-width: 1024px) {
  .V57d7f4f5694ee57d7f4f5694f9 {
    height: 250px;
  }

  .P57d7ef9772da657d7ef9772dc5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 900px) {
  .V57d7f4f5694ee57d7f4f5694f9 {
    height: 220px;
  }
}
@media screen and (max-width: 810px) {
  .V57d7f4f5694ee57d7f4f5694f9 {
    height: 200px;
  }
}
@media screen and (max-width: 700px) {
  .V57d7f4f5694ee57d7f4f5694f9 {
    height: 175px;
  }
}
@media screen and (max-width: 640px) {
  .V57d7f4f5694ee57d7f4f5694f9 {
    height: 175px;
  }

  .P57d7ef9772da657d7ef9772dc5 .columns {
    padding-top: .625em;
    padding-bottom: .625em;
  }
}
@media only screen and (max-width: 500px) {
  .V57d7f4f5694ee57d7f4f5694f9 {
    height: 150px;
  }
}
