.instagram-feed {
  border-top: 1px solid #e0e0e0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.instagram-feed ol {
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0;
}

.instagram-feed h2 {
  text-align: center;
}

.instagram-feed ol li {
    display: inline-block;
    float: left;
    width: 20%;

    a {
        display: block;
        width: 100%;
        padding-bottom: 100%;
    }
}

@media only screen and (max-width: 800px) {
    .instagram-feed ol {
      margin-left: -5px;
    }
    .instagram-feed ol li {
        width: 33.3333333333%;
        box-sizing: border-box;
    }
}
@media (max-width: 640px) {
  .instagram-feed ol li {
    width: 50%;
  }
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #1c1c1c;

  .contains_logo {
    width: 200px;
    height: 200px;
    line-height: 200px;
    margin: 0 auto;
    background: #fff;
    border-radius: 9999px;
    overflow: hidden;
  }

  img {
    display: block;
    width: 90%;
    margin: 0 auto;
  }
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 .column {
  padding: 0px;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 ol {
  margin-bottom: 0px;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 ol li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0px;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 ol li a {
  color: #212121;
  text-transform: uppercase;
  letter-spacing: 3px;
  transition: all 0.2s ease-in-out;
  background: #777;
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  padding: 0px;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 ol li a:hover {
  background: #fff;
  color: #212121;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 hr {
  display: none;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 img {
  width: 70%;
  margin: 0 auto;
  display: block;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 h2 {
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  font-size: 1em;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 ul li {
  display: block;
  padding-bottom: 10px;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 ul li a {
  color: #777;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.9em;
  transition: all 0.2s ease-in-out;
}
.c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 ul li a:hover {
  color: #525252;
}

.e1a13a265bded2e86a37d590e36feeacffa89149e {
  padding-top: 20px;
}
@media only screen and (max-width: 700px) {
  .c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 .contains_logo {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 640px) {
  .c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 hr {
    display: block;
    border: 0px;
    width: 100%;
    height: 1px;
    background: #626262;
  }
  .c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 .column {
    text-align: center;
  }
  .c3795ecfe4aef5d6ca2601ce5980b47395eb0d93 ol li {
    margin: 0px;
    text-align: center !important;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 0.9em;
  }
}
