.logo-row {
	padding-top: 40px;
	padding-bottom: 40px;
	text-align: center;

	h2 {
		font-weight: normal;
		padding-bottom: 20px;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		width: 80%;
		margin: 0 auto;

		li {
			display: inline-block;
			width: 14.2857143%;
			margin-bottom: 20px;
			vertical-align: center;

			img {
				display: block;
				width: 80%;
				height: auto;
				vertical-align: center;
				margin: 0 auto;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.logo-row ul li {
		width: 33.3333%;
	}
}

@media only screen and (max-width: 640px) {
	.logo-row ul li {
		width: 50%;
	}
}