.K57d7aec249c4557d7aec249c66 {
	position: relative;
	height: 88vh;
	margin-top: 90px;

	.table {
		background: rgba(#000, 0.2);

		.table {
			background: transparent;
		}
	}

	h1
	{
		font-size: 4em;
		color: #fff;
	}

	p
	{
		font-size: 2em;
		color: #fff;
	}

	.logo_container
	{
		width: 350px;
		height: 350px;
		background: #fff;
		display: block;
		border-radius: 9999px;
		margin: 0 auto;
		overflow: hidden;

		img {
			width: 85%;
			display: block;
			margin: 0 auto;
		}
	}
}

#skisk-home-link {
	font-size: 2em;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -125px;
	margin-bottom: 60px;
	display: block;
	height: 45px;
	line-height: 40px;
	width: 250px;
	border: 2px solid white;
	color: #fff;
	transition: 0.2s ease-in-out;
	border-radius: 4px;
}

#skisk-home-link:hover {
	color: #000;
	background: #fff;
}

@media (max-width: 1024px) {
	.K57d7aec249c4557d7aec249c66 {
		height: 70vh;
	}
}

@media (max-width: 800px) {
	.K57d7aec249c4557d7aec249c66 {
		height: 480px;
	}
}

@media (max-width: 640px) {
	.K57d7aec249c4557d7aec249c66 {
		height: 440px;
	}
}

@media (max-width: 600px) {
	.K57d7aec249c4557d7aec249c66 {
		height: 420px;
	}
}

@media (max-width: 520px) {
	.K57d7aec249c4557d7aec249c66 {
		height: 400px;
	}
}

@media (max-width: 500px) {
	.K57d7aec249c4557d7aec249c66 {
		height: 360px;
	}
}

@media (max-width: 420px) {
	.K57d7aec249c4557d7aec249c66 {
		height: 320px;
	}

	#skisk-home-link {
		font-size: 1.25em;
		height: 40px;
		line-height: 35px;
	}
}

.Z57d7aef7322e457d7aef73257e {
	position: absolute;
	top: 50%;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	height: 80%;
	transform: translateY(-50%);
}
.Z57d7aef7322e457d7aef73257e h1 {
	max-width: 400px;
	line-height: 1.5;
	color: #fff;
	margin: 0 auto;
	font-weight: bold;
	font-size: 2.2em;
}
.Z57d7aef7322e457d7aef73257e img {
	display: block;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	width: 300px;
	height: auto;
}

.T57d7af5f2987157d7af5f2987f {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	height: 100%;
}
.T57d7af5f2987157d7af5f2987f div {
	height: 100%;
}
.K57d7aec249c4557d7aec249c66 a:hover {
	background: #fff;
	color: #333;
}

.K57d7aec249c4557d7aec249c66 .slick-dots {
	position: absolute;
	bottom: 0;
	left: 0;
	text-align: center;
	z-index: 2;
	right: 0;
	margin-top: -30px;
}
.K57d7aec249c4557d7aec249c66 .slick-dots li {
	display: inline-block;
	border: 2px solid #fff;
	margin-left: 10px;
	margin-right: 10px;
	content: '';
	color: transparent;
	width: 20px;
	height: 20px;
	border-radius: 99999px;
	box-sizing: border-box;
	margin-bottom: 0px;
	padding: 0px;
	overflow: hidden;
}
.K57d7aec249c4557d7aec249c66 .slick-dots li button {
	box-sizing: border-box;
	background: transparent;
	width: 8px;
	height: 8px;
	border-radius: 999999px;
	margin-top: 4px;
}
.K57d7aec249c4557d7aec249c66 .slick-dots li.slick-active button {
	background: #fff;
}
@media only screen and (max-width: 640px) {
	.Z57d7aef7322e457d7aef73257e h1 {
		font-size: 1.5em;
		max-width: 80%;
	}
}
.X6b8986a6185328acaf0bf913619ed262d22d0699 {
	position: relative;
	overflow: hidden;
	text-align: center;
}
.X6b8986a6185328acaf0bf913619ed262d22d0699 .columns {
	position: relative;
}
.X6b8986a6185328acaf0bf913619ed262d22d0699 .float-left {
	width: 100%;
	padding-top: 80px;
}
.X6b8986a6185328acaf0bf913619ed262d22d0699 h2 {
	font-family: sans-serif;
	color: #444;
	width: 75%;
	font-size: 2.4em;
	font-weight: normal;
	margin: 0 auto;
}
.X6b8986a6185328acaf0bf913619ed262d22d0699 h3 {
	font-family: sans-serif;
	color: #444;
	font-size: 14px;
	text-transform: uppercase;
	width: 75%;
	margin: 0 auto;
	letter-spacing: 2px;
}
.X6b8986a6185328acaf0bf913619ed262d22d0699 p {
	padding-top: 15px;
	font-family: sans-serif;
	width: 75%;
	line-height: 1.7;
	font-size: 1.2em;
	margin: 0 auto;
	max-width: 600px;
	color: #444;
}
.X6b8986a6185328acaf0bf913619ed262d22d0699 a {
	display: block;
	width: 200px;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	font-family: sans-serif;
	color: #3D2314;
	border: 1px solid #3D2314;
	border-radius: 4px;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin: 0 auto;
	margin-top: 30px;
	transition: all 0.2s ease-in-out;
}
.X6b8986a6185328acaf0bf913619ed262d22d0699 a:hover {
	background: #3D2314;
	color: #fff;
}
@media only screen and (max-width: 1024px) {
	.X6b8986a6185328acaf0bf913619ed262d22d0699 .float-left {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.E8d9a0bd32a7c080aefc  {
		background-attachment: scroll !important;
	}
}

@media only screen and (max-width: 640px) {
	.X6b8986a6185328acaf0bf913619ed262d22d0699 .float-left {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.smaller-hidden {
		display: none;
	}
	.X6b8986a6185328acaf0bf913619ed262d22d0699 .float-left h2 {
		font-size: 1.5em;
	}
	.X6b8986a6185328acaf0bf913619ed262d22d0699 .float-left p {
		max-width: 600px;
		width: 80%;
		font-size: 1.1em;
	}
	.X6b8986a6185328acaf0bf913619ed262d22d0699 .float-left a {
		font-size: 0.9em;
		width: 180px;
	}
}
.Nd0591fc61ce3621b817 {
	padding-top: 60px;
	padding-bottom: 90px;
}

.Ke77cc484aa0bf0cbc47 {
	display: flex;
}

.D47b7a8f131f3b728256 {
	background: #333;
	margin-bottom: 1.875rem;
}
.D47b7a8f131f3b728256 .table {
	text-align: center;
	background: rgba(28, 28, 28, 0.2);
	transition: all 0.2s ease-in-out;
}
.D47b7a8f131f3b728256 .table:hover {
	background: rgba(28, 28, 28, 0.8);
}
.D47b7a8f131f3b728256 .table span {
	color: #fff;
	font-size: 2em;
}

.Pf394151fee109640f4f {
	height: 425px;
}

.Md689373c81f8de7527f {
	height: 275px;
}

@media only screen and (max-width: 1024px) {
	.Pf394151fee109640f4f {
		height: 375px;
	}

	.Md689373c81f8de7527f {
		height: 225px;
	}

	.Nd0591fc61ce3621b817 {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}
@media only screen and (max-width: 640px) {
	.Ke77cc484aa0bf0cbc47 {
		flex-wrap: wrap;
	}

	.D47b7a8f131f3b728256 {
		height: 200px !important;
		margin-bottom: 0px;
		border-bottom: 1px solid #fff;
	}

	.Nd0591fc61ce3621b817 {
		padding: 0px;
	}
	.Nd0591fc61ce3621b817 .columns {
		padding: 0px;
	}
}
.E8d9a0bd32a7c080aefc {
	height: 500px;
	background: #333;
}
.E8d9a0bd32a7c080aefc .table .small-12.medium-6.large-5 {
	background: #fff;
	padding: 30px;
	box-sizing: border-box;
}
.E8d9a0bd32a7c080aefc .table .small-12.medium-6.large-5 span {
	color: #0074D9;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-bottom: 0px;
	display: block;
	font-size: 0.9em;
}
.E8d9a0bd32a7c080aefc .table .small-12.medium-6.large-5 h2 {
	font-size: 1.25em;
	margin-top: 10px;
	color: #444;
	line-height: 1.5;
	font-weight: normal;
}

@media only screen and (max-width: 1024px) {
	.E8d9a0bd32a7c080aefc {
		height: 400px;
	}
}
@media only screen and (max-width: 640px) {
	.E8d9a0bd32a7c080aefc {
		height: 300px;
	}
}
.eca9c24aac7d49ae924da3a64438568f {
	padding-top: 80px;
	padding-bottom: 80px;
	border-bottom: 1px solid #f0f0f0;
}

.eca9c24aac7d49ae924da3a64438568f h2 {
	padding-bottom: 30px;
	text-align: center;
	color: #444;
	font-size: 2.4em;
	font-weight: normal;
}

.cb25834ab523459fa64f1b26bb8e4084 {
	height: 350px;
	margin-bottom: 30px;
	width: 100%;
}
.b051a5b9277d4426ae13bc58510915f5 ul {
	margin-bottom: 0px;
	margin-top: 0px;
	text-align: center;
	padding-top: 20px;
}

.b051a5b9277d4426ae13bc58510915f5 ul li {
	display: inline-block;
	margin-left: 10px;
	margin-right: 10px;
}

.b051a5b9277d4426ae13bc58510915f5 ul li a {
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	background: #000;
	color: #fff;
	border-radius: 9999px;
	font-size: 1.1em;
}
.b051a5b9277d4426ae13bc58510915f5 span {
	padding-bottom: 10px;
	display: block;
	font-size: 1em;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #444;
	text-align: center;
}
.b051a5b9277d4426ae13bc58510915f5 p {
	line-height: 1.75;
	margin-bottom: 0px;
	padding-bottom: 10px;
	text-align: center;
	max-width: 85%;
	margin-left: auto;
	margin-right: auto;
}

@media only screen and (max-width: 1024px) {
	.cb25834ab523459fa64f1b26bb8e4084 {
		height: 200px;
	}
}
@media only screen and (max-width: 640px) {
	.eca9c24aac7d49ae924da3a64438568f {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.eca9c24aac7d49ae924da3a64438568f .columns {
		padding: 0px;
		padding-bottom: 20px;
	}

	.cb25834ab523459fa64f1b26bb8e4084 {
		margin-bottom: 20px;
	}
}
