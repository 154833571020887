@font-face {
    font-family: 'GT Walsheim';
    src: url('/fonts/GTWalsheim-Light.eot');
    src: url('/fonts/GTWalsheim-Light.eot?#iefix') format('embedded-opentype'), url('/fonts/GTWalsheim-Light.woff2') format('woff2'), url('/fonts/GTWalsheim-Light.woff') format('woff'), url('/fonts/GTWalsheim-Light.ttf') format('truetype'), url('/fonts/GTWalsheim-Light.svg#/fonts/GTWalsheim-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

body.chocolat-open .chocolat-overlay {
  background: #000;
  opacity: 1;
}

.chocolat-wrapper .chocolat-bottom {
  background: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
p,
ol li,
ul li,
button,
label,
sup,
input,
table {
    font-family: 'GT Walsheim', sans-serif !important;
}

table{
  font-weight: normal !important;
}

label {
  font-weight: normal !important;
}
.table {
    display: table;
    width: 100%;
    height: 100%;
}
.table-cell {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
}
