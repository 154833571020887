.d87fc941e50d779e0c9 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.d87fc941e50d779e0c9 span {
  display: block;
  width: 100%;
  margin-bottom: 0px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-bottom: 20px;
  color: #444;
}
.d87fc941e50d779e0c9 h2 {
  margin-top: 0px;
  padding-top: 0px;
  text-align: center;
  font-size: 2.4em;
  color: #444;
  padding-bottom: 40px;
  font-weight: normal;
}

.Rda090e1de8c805a8144 {
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  padding-bottom: 20px;
}
.Rda090e1de8c805a8144 span {
  text-align: left;
  padding-bottom: 10px;
  font-size: 1.15em;
  padding-top: 20px;
}
.Rda090e1de8c805a8144 span.f1 {
  padding-top: 0px;
}
.Rda090e1de8c805a8144 p a {
  color: #1c1c1c;
  opacity: 0.5;
}
.Rda090e1de8c805a8144 p a:hover {
  opacity: 1;
}
.Rda090e1de8c805a8144 address span {
  padding-bottom: 5px;
  padding-top: 0px;
  text-transform: none;
  letter-spacing: normal;
  font-style: normal;
  font-size: 0.9em;
}

.Ifba40848549386d0400 span {
  display: block;
  text-transform: none;
  letter-spacing: normal;
  text-align: left;
  font-weight: bolder;
  font-size: 1.1em;
}
.Ifba40848549386d0400 p {
  color: #444;
  line-height: 1.7;
}

@media only screen and (max-width: 1024px) {
  .Rda090e1de8c805a8144 {
    border: 0px;
    border-bottom: 1px solid #ccc;
    padding-top: 0px;
    padding-bottom: 40px;
  }

  .d87fc941e50d779e0c9 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .d87fc941e50d779e0c9 h2 {
    padding-bottom: 20px;
  }

  .Ifba40848549386d0400 {
    padding-top: 40px;
  }
}

.V910a67a96ae61faf8eb {
  width: 50%;
}

.W610dc1bc247b0d1c791 {
  height: 300px;
}

.C4cb617df6588df03bcd {
  width: 25%;
}

.Oc15c1f73950b06f4dc8 {
  height: 600px;
}

@media only screen and (max-width: 1024px) {
  .B879402efbc9ad8158ff {
    display: none;
  }

  .C4cb617df6588df03bcd {
    width: 50%;
  }

  .W610dc1bc247b0d1c791 {
    height: 250px;
  }

  .Oc15c1f73950b06f4dc8 {
    height: 500px;
  }
}
@media only screen and (max-width: 640px) {
  .W610dc1bc247b0d1c791 {
    display: none;
  }

  .V910a67a96ae61faf8eb {
    width: 100%;
  }

  .Oc15c1f73950b06f4dc8 {
    height: 300px;
  }
}
