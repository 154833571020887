.calendar-container {
  padding-top: 60px;
}

.fc-button-group, .fc button
{
  display: block !important;
}

.calendar-container table {
  margin-bottom: 0px;
}

.fc tbody .fc-row .fc-content-skeleton table {
  min-height: 80px;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  margin-right: 5px;
  margin-top: 3px;
}

.calendar-container tbody,
.calendar-container tfoot,
.calendar-container thead {
  border: 0px;
}

.calendar-container .fc-event {
  background: #0074D9 !important;
  box-sizing: border-box;
  padding: 5px 10px;
}

.fc-event span {
  color: #fff !important;
  font-size: 1em !important;
  line-height: 1.5 !important;
}

.fc-unthemed .fc-list-empty {
  font-family: 'GT Walsheim', sans-serif !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.calendar-container .fc-day-grid-event .fc-content {
  white-space: normal;
}

.events-desc p,
.events-desc {
  font-family: "GT Walsheim", sans-serif !important;
  margin: 0 auto;
  line-height: 1.5;
  width: 100%;
  max-width: 100%;
  text-align: left;
}

.events-desc h2 {
  text-align:left;
  margin-top:30px !important;
}
