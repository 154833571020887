.B6ceecc71805d05ae39ebeb251961dd3e8ba3f320 {
  height: 85vh;
  position: relative;
  margin-top: 90px;

  h1 {
    margin-top: 0px;
    padding-top: 5px;
    font-size: 2.4em;
    letter-spacing: 5px;
    font-weight: normal;
    text-transform: uppercase;
    color: #fff;
  }

  span,
  p {
    margin-bottom: 0px;
    color: #fff;
    opacity: 0.8;
    font-size: 1.2em;
    line-height: 1.5;
  }
}

#notes-link {
  width: 100%;
  display: block;
  text-transform: uppercase;
  color: #fff;
  background: url('/assets/sake-link-bg.jpg');
  background-size: cover;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-size: 2em;
  margin-bottom: 40px;

  .table {
    background: rgba(#000, 0.4);
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.O5258c3e1fbcfd30338721eba1db8efe97848cb4c {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6));
}

.M396091e776adc34e3bd9e5b1ceba8ea1fb49373e {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 20px;
  color: #fff;
}
.M396091e776adc34e3bd9e5b1ceba8ea1fb49373e span {
  margin-bottom: 0px;
  color: #fff;
  opacity: 0.8;
  font-size: 1.2em;
  line-height: 1.5;
}
.M396091e776adc34e3bd9e5b1ceba8ea1fb49373e h1 {
  margin-top: 0px;
  padding-top: 5px;
  font-size: 2.4em;
  letter-spacing: 5px;
  font-weight: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 640px) {
  .B6ceecc71805d05ae39ebeb251961dd3e8ba3f320 {
    height: 70vh;
  }

  .M396091e776adc34e3bd9e5b1ceba8ea1fb49373e h1 {
    font-size: 1.5em;
    padding-top: 10px;
    letter-spacing: 5px;
  }
  .M396091e776adc34e3bd9e5b1ceba8ea1fb49373e span {
    font-size: 0.9em;
  }
}
@media only screen and (max-width: 640px) and (orientation: landscape) {
  .B6ceecc71805d05ae39ebeb251961dd3e8ba3f320 {
    height: 120vh;
  }
}
.Tf1801abefcc2ca755c1 {
  padding-bottom: 90px;
  text-align: center;
}
.Tf1801abefcc2ca755c1 h2 {
  margin-bottom: 10px;
  font-weight: normal;
}
.Tf1801abefcc2ca755c1 span {
  margin-top: 0px;
  display: block;
  font-style: italic;
}

.U908fb45d281ba14b282 {
  padding-top: 40px;
}
.U908fb45d281ba14b282 a {
  color: #444;
  float: right;
  display: block;
  width: auto;
  margin: 10px 0px;
  font-weight: normal;
}
.U908fb45d281ba14b282 a:hover {
  color: #0074D9;
}
.U908fb45d281ba14b282 span {
  display: block;
  text-align: right;
  width: 100%;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.9em;
}

.Db9906ff67f685c6c5c9 {
  width: 50%;
  height: 400px;
  background: #333;
  float: left;
  position: relative;
}
.Db9906ff67f685c6c5c9 a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(28, 28, 28, 0.4);
  transition: all 0.2s ease-in-out;
  margin: 0px;
  float: none;
}
.Db9906ff67f685c6c5c9 a:hover {
  background: rgba(28, 28, 28, 0.2);
}
.Db9906ff67f685c6c5c9 a span {
  bottom: 0;
  position: absolute;
  left: 0;
  float: none;
  text-align: left;
  text-transform: none;
  letter-spacing: 0px;
  font-size: 1.8em;
  line-height: 1.3;
  color: #fff;
  margin-bottom: 30px;
  width: 90%;
  margin-left: 5%;
}
.Db9906ff67f685c6c5c9 a span b {
  font-size: 0.5em;
  display: block;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  font-weight: normal;
}
.Db9906ff67f685c6c5c9 a span .O5422c4839e58288559c {
  font-size: 0.5em;
  position: static;
  width: 100%;
  margin-bottom: 0px;
  margin-left: 0%;
  margin-top: 10px;
}

.Ee6f191500bd438bfb9e {
  width: 25%;
  background: #333;
  box-sizing: border-box;
  border-left: 10px solid #fff;
  border-bottom: 10px solid #fff;
  height: 200px;
  float: left;
  position: relative;
}
.Ee6f191500bd438bfb9e a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  margin: 0px;
  transition: all 0.2s ease-in-out;
  color: #fff;
}
.Ee6f191500bd438bfb9e a:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}
.Ee6f191500bd438bfb9e a span {
  text-align: left;
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 5%;
  margin-bottom: 15px;
  font-weight: normal;
}
.Ee6f191500bd438bfb9e a span b {
  font-size: 0.8em;
  font-weight: normal;
}
.Ee6f191500bd438bfb9e a span span {
  position: static;
  margin-bottom: 0px;
  text-transform: none;
  letter-spacing: 0px;
  font-size: 1.2em;
}

.Ve318d8d13da1e1aebca {
  width: 25%;
  background: #333;
  height: 200px;
  float: left;
  box-sizing: border-box;
  border-left: 10px solid #fff;
  position: relative;
}
.Ve318d8d13da1e1aebca a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  margin: 0px;
  transition: all 0.2s ease-in-out;
  color: #fff;
}
.Ve318d8d13da1e1aebca a:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}
.Ve318d8d13da1e1aebca a span {
  text-align: left;
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 5%;
  margin-bottom: 15px;
}
.Ve318d8d13da1e1aebca a span b {
  font-size: 0.8em;
}
.Ve318d8d13da1e1aebca a span span {
  position: static;
  margin-bottom: 0px;
  text-transform: none;
  letter-spacing: 0px;
  font-size: 1.2em;
}

.ajax-loaded
{
    border-top: 10px solid #fff;

    &:first-child
    {
        border-left: 0px;
    }
}

.first-ajax
{
  border-left: 0px !important;
}

@media only screen and (max-width: 640px) {
  .Tf1801abefcc2ca755c1 {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .Db9906ff67f685c6c5c9 {
    width: 100%;
  }

  .Ee6f191500bd438bfb9e,
  .Ve318d8d13da1e1aebca {
    width: 100%;
    border: 0px;
  }
}
@media only screen and (max-width: 400px) {
  .Db9906ff67f685c6c5c9 {
    height: 300px;
  }

  .Ee6f191500bd438bfb9e,
  .Ve318d8d13da1e1aebca {
    height: 150px;
  }
  .Ee6f191500bd438bfb9e span,
  .Ve318d8d13da1e1aebca span {
    font-size: 0.9em !important;
  }
}

.Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b {
  padding-top: 140px;
  padding-bottom: 40px;
}
.Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b table {
  margin: 0 auto;
}
.Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b h2 {
  font-size: 2.4em;
  color: #444;
}
.Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b blockquote {
  border-left: 0px;
  padding: 0px;
  font-size: 1.25em;
  color: #a0a0a0;
  padding-bottom: 30px;
  margin-bottom: 0px;
}
.Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b img {
  margin-bottom: 30px;
  display: block;
}
.Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b p {
  font-size: 1.1em;
  line-height: 1.75;
  color: #1c1c1c;
  padding-bottom: 30px;
  margin-bottom: 0px;
}

#ajax-more {
  display: block;
  margin: 0 auto;
  margin-top: 60px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 640px) {
  .Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b blockquote {
    font-size: 1em;
  }
  .Bd78b3f852dbdac3e770bd7a812b3be9e37a3992b p {
    font-size: 0.9em;
  }
}
