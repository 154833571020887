.required
{
  color: #ff4136;
}

.reserve-form
{
  padding-top: 50px;
  text-align: left !important;

  button
  {
    margin: 0 auto;
    text-align: center;
    max-width: 200px;
    display: block;
    margin-top: 20px;
    font-size: 1.1em;
    color: #444;
  }

  input
  {
    margin-bottom: 30px;
  }

  span
  {
    color: #ff4136;
    font-size: 0.9em !important;
    font-weight: normal !important;
  }

  label
  {
    color: #444 !important;
    font-size: 1.2em !important;
    text-align: left !important;
  }

  sup
  {
    font-size: 1.1em !important;
  }
}
